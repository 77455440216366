<template lang='pug'>
</template>

<script>
import {STORAGE} from '@/api/storage';

export default {
	name: 'CardSuccess',
	data : () => ({
		needCloseTab: false
	}),
	created () {
		let route = STORAGE.getRedirectUrl() ? STORAGE.getRedirectUrl() : {name: 'finance'};

		STORAGE.setCard(true);
		STORAGE.setRedirectUrl(false);

		if ( route.name != 'finance' ) return this.needCloseTab = true;

		setTimeout(() => {
			return this.$router.replace(route);
		}, 1000);
	},
	watch: {
		me (nv) {
			if ( nv && nv.id && this.needCloseTab ) window.close();
		}
	}
}
</script>
